function setElementEnabled() {
    document.getElementById('hdnSwipeData').hidden = false;
    setSaleElementEnabled();
    document.getElementById('hdnSwipeEnable').hidden = false;
    document.getElementById('hdnSwipeDisable').hidden = true;
}
function setSaleElementEnabled() {
    document.getElementById('expiration').value = '';
    document.getElementById('cardNumber').value = '';
    document.getElementById('cardName').value = '';
    document.getElementById('hdnSwipeData').focus();
    document.getElementById('hdnSwipeData').value = "";
}
function setElementDisEnabled() {
    document.getElementById('hdnSwipeData').hidden = true;
}
